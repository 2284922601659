import axios from "axios";
import url from "../url";
import { getArray, setQuery, getJson, setData } from "../resources/stickersResource";

export default class PlayListRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle;
    }

    async index(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexStickerPacks"), { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }

    async show(stickerId) {
        const response = await axios.get(url("showStickerPack", { stickerId: stickerId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async showSticker(stickerId) {
        const response = await axios.get(url("showSticker", { stickerId: stickerId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async editSticker(id, data) {
        const response = await axios.put(url("updateSticker", { stickerId: id }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async storeSticker(data) {
        const response = await axios.post(url("storeStickerPak"), data);
        if (response.status === 201) {
            return response;
        }
    }
    async addNewSticker(data) {
        const response = await axios.post(url("addNewStickerToPack"), data);
        if (response.status === 201) {
            return response;
        }
    }
    async convert(data) {
        const response = await axios.post(url("convertAdminPlayList"), data);
        if (response.status === 201) {
            return getJson(response.data);
        }
    }
    async update(id, data) {
        // const json = setData(data)
        const response = await axios.put(url("updateStickerPack", { stickerId: id }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async updateSort(playlistId) {
        const response = await axios.put(url("updateSortAdminPlaylist", { playlist: playlistId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async deleteSticker(id) {
        const response = await axios.delete(url("deleteSticker", { stickerId: id }));
        if (response.status === 200) {
            return true;
        }
    }
    async delete(id) {
        const response = await axios.delete(url("deleteStickerPack", { stickerId: id }));
        if (response.status === 200) {
            return true;
        }
    }
    async sendToTop(id) {
        const response = await axios.get(url("sendToTopSticker", { stickerId: id }));
        if (response.status === 200) {
            return true;
        }
    }
    async packSendToTop(id) {
        const response = await axios.get(url("sendToTopStickerPack", { stickerId: id }));
        if (response.status === 200) {
            return true;
        }
    }
    
    // async attach(playlistId, musicId) {
    //     const json = { music_id: musicId };
    //     const response = await axios.post(url("attachAdminPlaylist", { playlist: playlistId }), json);
    //     if (response.status === 200) {
    //         return response.data.data;
    //     }
    // }

    // async detach(playlistId, musicId) {
    //     const json = { music_id: musicId };
    //     const response = await axios.post(url("detachAdminPlaylist", { playlist: playlistId }), json);
    //     if (response.status === 200) {
    //         return response.data.data;
    //     }
    // }
}
